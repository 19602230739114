import React, { forwardRef } from 'react';
import { Snackbar, Alert } from '@mui/material';

const CustomSnackbar = forwardRef(
  ({ message, severity, open, onClose, autoHideDuration = 4000 }, ref) => (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ref={ref}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
);

export default CustomSnackbar;
