import React, { useState, useEffect, useRef } from 'react';
import {
  APIProvider,
  Map,
  Marker,
  ControlPosition,
} from '@vis.gl/react-google-maps';
import { SearchBox } from './SearchBox/SearchBox';
import MapHandler from './SearchBox/MapHandler';

const SubMapComponent = ({
  defaultCenter,
  position,
  defaultZoom,
  onClick = () => {},
  title,
  onLoad,
  mapModalOpen,
}) => {
  const [selectedPlace, setSelectedPlace] = useState(null);

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && defaultCenter) {
      mapRef.current.setCenter(defaultCenter);
    }
  }, [defaultCenter]);

  useEffect(() => {
    if (selectedPlace && selectedPlace.geometry && mapRef.current) {
      const location = selectedPlace.geometry.location;
      mapRef.current.setCenter(location);
    }
  }, [selectedPlace]);

  const handleMapClick = event => {
    const { latLng } = event.detail;
    if (latLng) {
      const latLngObj = {
        lat: latLng.lat,
        lng: latLng.lng,
      };
      onClick(latLngObj);
    }
  };

  const handleTilesLoaded = map => {
    console.log('Tiles loaded');
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
      {mapModalOpen && (
        <SearchBox
          controlPosition={ControlPosition.RIGHT_TOP}
          onPlaceSelect={setSelectedPlace}
          mapRef={mapRef}
        />
      )}
      <Map
        ref={mapRef}
        style={{ width: '100%', height: '100%', borderRadius: '4px' }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom || 10}
        gestureHandling={'greedy'}
        options={{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
        }}
        onClick={handleMapClick}
        onTilesLoaded={handleTilesLoaded}
      >
        {position && <Marker position={position} title={title} />}
        <MapHandler
          ref={mapRef}
          place={selectedPlace}
          subMapCenter={defaultCenter}
        />
      </Map>
    </APIProvider>
  );
};

export default SubMapComponent;
