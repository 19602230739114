export async function fetchUserLocationByIP() {
  try {
    const response = await fetch('https://ipinfo.io/json?token=f82c539a84acfc');

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const [lat, lng] = data.loc.split(',');

    return { lat: parseFloat(lat), lng: parseFloat(lng), source: 'ip' };
  } catch (error) {
    console.error(
      'Error fetching user location by IP:',
      error.message || error
    );
    return null;
  }
}

export async function getUserLocation(updateCenterCallback) {
  const locationByIP = await fetchUserLocationByIP();
  let resolvedLocation = locationByIP;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        const geolocation = { lat: latitude, lng: longitude, source: 'geo' };

        if (updateCenterCallback) {
          updateCenterCallback(geolocation);
        }

        localStorage.setItem('userLocation', JSON.stringify(geolocation));
      },
      error => {
        console.error('Error fetching geolocation:', error.message || error);
      },
      { timeout: 10000 }
    );
  }

  localStorage.setItem('userLocation', JSON.stringify(resolvedLocation));
  return resolvedLocation;
}

export async function getUserLocationbyGeo() {
  if (!navigator.geolocation) {
    throw new Error('Geolocation is not supported');
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        resolve({ lat: latitude, lng: longitude, source: 'geo' });
      },
      error => {
        reject(`Error fetching geolocation: ${error.message || error}`);
      }
    );
  });
}

