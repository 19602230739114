import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Box,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MapControl } from '@vis.gl/react-google-maps';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { t } from 'i18next';

export const SearchBox = ({ controlPosition, onPlaceSelect }) => {
  const [isOpen, setIsOpen] = useState(false); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    const autocompleteInstance = new places.Autocomplete(
      inputRef.current,
      options
    );

    // Листенер для зміни місця
    autocompleteInstance.addListener('place_changed', () => {
      const place = autocompleteInstance.getPlace();
      if (place.geometry) {
        onPlaceSelect(place); // Передаємо вибране місце
        setSearchQuery(place.name || ''); // Записуємо знайдену локацію в стан
      }
    });

    // Очищення слухача при демонтажі
    return () => {
      if (window.google) {
        window.google.maps.event.clearListeners(
          autocompleteInstance,
          'place_changed'
        );
      }
    };
  }, [places, onPlaceSelect]);


  const handleClearClick = () => {
    setSearchQuery('');
  };

  return (
    <MapControl position={controlPosition}>
      <Box
        sx={{
          position: 'absolute',
          margin: '10px',
          right: 0,
          display: 'inline-flex',
        }}
      >
        {!isOpen ? (
          <Tooltip title={t('ui:map.open')}>
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              color="primary"
              sx={{
                padding: 1,
                bgcolor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px -1px',
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t('ui:map.close')}>
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              color="primary"
              sx={{
                padding: 1,
                bgcolor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px -1px',
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: isOpen ? 45 : 0,
            width: isOpen ? 264 : 0,
            zIndex: 10,
            bgcolor: '#2196f3',
            borderRadius: 2,
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px -1px',
          }}
        >
          <TextField
            inputRef={inputRef}
            placeholder={t('ui:map.enterLocation')}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{
              display: isOpen ? 'block' : 'none',
              '& .MuiOutlinedInput-root': {
                height: 40,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.33)',
                '&:hover fieldset': {
                  borderColor: '#2196f3',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2196f3',
                },
              },
              '& .MuiInputBase-input': {
                color: '#2196f3',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearClick}
                    size="small"
                    color="primary"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </MapControl>
  );
};
