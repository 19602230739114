import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { selectUser } from 'redux/auth/authSlice';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Button,
  useMediaQuery,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import json2mq from 'json2mq';
import './UserReports.styled.css';

export const UserSubmissions = () => {
  const { t, i18n } = useTranslation();
  const [endpointData, setEndpointData] = useState([]);
  const db = getFirestore();
  const userId = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayedReports, setDisplayedReports] = useState([]);
  const [displayAllNewReports] = useState(false);

  const [displayedRows] = useState(3);

  const matches = useMediaQuery(json2mq({ minWidth: 500 }));
  // const matchesTab = useMediaQuery(json2mq({ minWidth: 1440 }));

  useEffect(() => {
    const reportsRef = collection(db, 'Reports');
    const reportsQuery = query(
      reportsRef,
      where('originalReportUserId', '==', userId)
    );

    getDocs(reportsQuery).then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setEndpointData(data);
    });
  }, [db, userId]);

  useEffect(() => {
    if (endpointData.length === 0) return;

    const displayed = endpointData.filter(report => !report.archived);

    setDisplayedReports(displayed);
  }, [endpointData]);

  const handleEditClick = report => {
    dispatch(setSelectedReport(report));
    navigate('/edit-report');
  };

  const time = props =>
    props.timeObserved.seconds * 1000 + props.timeObserved.nanoseconds / 1e6;

  const recentReports = displayedReports
    .filter(
      report =>
        !report.closedForUpdates && report.verificationStatus === 'notStarted'
    )
    .sort((a, b) => time(b) - time(a));

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          minWidth: 150,
          '& .MuiTypography-root': {
            fontSize: { xs: '9pt', md: '11pt' },
          },
          '& .MuiButton-root': {
            fontSize: { xs: '8pt', md: '11pt' },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>{t('reports:submission.report')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('reports:submission.dateAdded')}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t('reports:submission.verifiedStatus')}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{t('reports:submission.edit')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentReports
            .slice(
              0,
              displayAllNewReports ? recentReports.length : displayedRows
            )
            .map(report => (
              <TableRow key={report.id}>
                <TableCell>
                  <Typography variant="body1">
                    {t(`hazards:hazardName.${report.reportType}`)}:
                  </Typography>
                  {/* <br /> */}
                  <Typography variant="body1">
                    {report.typeBomb
                      ? report.typeBomb
                      : t(
                          `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                        )}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {new Date(Number(report.timeObserved.seconds * 1000))
                      .toLocaleDateString(
                        i18n.language === 'ua' ? 'ua-UA' : 'en-US',
                        {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        }
                      )
                      .replace(',', '')}
                  </Typography>
                </TableCell>

                <TableCell>
                  {report.verificationStatus === 'notStarted' ? (
                    <Typography
                      color="secondary"
                      children={t('ui:report.notVerified')}
                    />
                  ) : (
                    <Typography
                      color="green"
                      children={t('ui:report.verified')}
                    />
                  )}
                </TableCell>

                <TableCell align="right">
                  {matches ? (
                    <Button
                      variant="outlined"
                      children={t('reports:submission.edit')}
                      onClick={() => handleEditClick(report)}
                    />
                  ) : (
                    <IconButton onClick={() => handleEditClick(report)}>
                      <OpenInFullIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
