import { Routes, Route } from 'react-router-dom';
import HomePage from 'pages/MainPage/HomePage';
import { useParams } from 'react-router-dom';

export const TerndingRoutes = () => {
  const { trendingCountry } = useParams();

  const continentCoordinates = {
    Ukraine: { lat: 49.390077, lng: 31.99953 },
    Israel: { lat: 31.24646, lng: 34.78294 },
    'north-america': { lat: 44.754, lng: -98.733 },
    'south-america': { lat: -14.235004, lng: -51.92528 },
    europe: { lat: 54.525961, lng: 15.255119 },
    asia: { lat: 34.047863, lng: 100.619655 },
    'middle-east': { lat: 30.585164, lng: 36.238414 },
    africa: { lat: 8.7832, lng: 34.5085 },
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<HomePage mapCenter={continentCoordinates[trendingCountry]} />}
      />
    </Routes>
  );
};
