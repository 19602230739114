import {
  Typography,
  Box,
  Button,
  Divider,
  List,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { DASHBOARD_URL } from 'utils/constants';
import { Container } from 'layouts/Container/Container';

const DashboardPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box sx={{ flexDirection: 'column', my: 2, alignItems: 'normal' }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold' }} my={1}>
          {t('ui:mainMenu.dashboardTitle')}
        </Typography>
        <Divider />

        <Typography variant="h3" my={1}>
          Details on dashboard features...
        </Typography>
        <List
          sx={{
            my: 1,
            listStyle: 'circle',
          }}
        >
          <ListItem>Dashboard text 1 .....</ListItem>
          <ListItem>Dashboard text 2 ....</ListItem>
          <ListItem>Dashboard text 3 ...</ListItem>
        </List>

        <Button variant="contained" sx={{ width: 200, mx: 'auto' }}>
          <a
            href={`${DASHBOARD_URL}/admin/dashboard`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            Start using
          </a>
        </Button>
      </Box>
    </Container>
  );
};
export default DashboardPage;
