import { initializeApp } from 'firebase/app';
import {
  signOut,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { GoogleAuthProvider } from 'firebase/auth';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
} from 'firebase/auth';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

export const createUser = async (email, password, firstName) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;
  await updateProfile(user, { displayName: firstName });
  return user;
};

export const saveUserToFirestore = async (user, data) => {
  const userRef = collection(db, 'users');
  const docId = user.uid;
  await setDoc(doc(userRef, docId), data);
};

export const checkIfUserExists = async userId => {
  const userDocRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userDocRef);
  return userDoc.exists()
    ? { exists: true, data: userDoc.data() }
    : { exists: false, data: null };
};

export const loginWithProvider = async provider => {
  return await signInWithPopup(auth, provider);
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const exit = () => {
  signOut(auth);
};

const sendVerificationEmail = async (user, showSnackbar) => {
  try {
    // Make sure the user object is the correct Firebase user object
    if (user && typeof user.getIdToken === 'function') {
      await sendEmailVerification(auth.currentUser);
      showSnackbar('Verification email sent', 'success');
    } else {
      showSnackbar(
        'Invalid user object for sending verification email',
        'error'
      );
      console.error('Invalid user object for sending verification email');
    }
  } catch (error) {
    showSnackbar('Error sending verification email:', error, 'error');
    console.error('Error sending verification email:', error);
    throw error;
  }
};
export const google = new GoogleAuthProvider();

const sendPasswordReset = async (email, showSnackbar) => {
  try {
    await sendPasswordResetEmail(auth, email);
    showSnackbar('Password reset email sent', 'success');
  } catch (err) {
    console.error(err);
    showSnackbar(err.message, 'error');
  }
};

export { app, auth, exit, db, sendVerificationEmail, sendPasswordReset };
