import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
  Timestamp,
} from 'firebase/firestore';

export const fetchReports = async () => {
  const db = getFirestore();
  const reportsCollection = collection(db, 'Reports');

  // Обчислення дати 90 днів тому
  const now = Timestamp.now();
  const ninetyDaysAgo = new Timestamp(now.seconds - 90 * 24 * 60 * 60, 0);

  // Запит до бази даних
  const queryRef = query(
    reportsCollection,
    where('archived', '==', false),
    where('timeObserved', '>=', ninetyDaysAgo)
  );

  const querySnapshot = await getDocs(queryRef);

  const reportDocs = querySnapshot.docs;

  const reportsWithDetails = reportDocs.map(doc => {
    const docData = doc.data();
    return {
      id: doc.id,
      ...docData,
    };
  });

  const reportsByType = reportsWithDetails.reduce((acc, report) => {
    const { reportType } = report;
    if (!acc[reportType]) acc[reportType] = [];
    acc[reportType].push(report);
    return acc;
  }, {});

  return reportsByType;
};
