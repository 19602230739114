import { useMap } from '@vis.gl/react-google-maps';
import React, { forwardRef, useEffect } from 'react';

const MapHandler = forwardRef(
  (
    {
      place,
      mapCenter,
      subMapCenter,
      userLocation,
      isUserCentered,
      setIsUserCentered,
    },
    ref
  ) => {
    const map = useMap();

    useEffect(() => {
      if (map && subMapCenter) {
        map.setCenter(subMapCenter);
        map.setZoom(14);
      }
    }, [map, subMapCenter]);

    useEffect(() => {
      if (!map) return;

      if (mapCenter) {
        map.setCenter(mapCenter);
        map.setZoom(6);
      }
    }, [map, mapCenter]);

    useEffect(() => {
      if (!map || !place) return;

      if (place.geometry) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else if (place.geometry.location) {
          map.setCenter(place.geometry.location);
          map.setZoom(14);
        }
      }
    }, [map, place]);

    useEffect(() => {
      if (map && userLocation) {
        map.setCenter(userLocation);
        map.setZoom(10);
        
      }
    }, [map, userLocation]);

    useEffect(() => {
      if (map && isUserCentered && userLocation) {
        map.setCenter(userLocation);
        map.setZoom(15);
        setIsUserCentered(false);
      }
    }, [map, userLocation, isUserCentered, setIsUserCentered]);

    return null;
  }
);

export default React.memo(MapHandler);
