import { useState } from 'react';
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Toolbar,
  Box,
  AppBar,
  useMediaQuery,
  Typography,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { SideBar } from 'components/common/SideBar/SideBar';
import logo1 from 'assets/img/logoHead.png';
import logo2 from 'assets/img/titleLogo.png';
import {
  selectUser,
  selectUserLocation,
  selectUserNotify,
} from 'redux/auth/authSlice';
import { SwitchLanguage } from 'components/common/SwitchLanguage/SwitchLanguage';
import json2mq from 'json2mq';
import { Container } from 'layouts/Container/Container';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import appbar from 'assets/img/appbar.webp';
import './MainAppBar.style.css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SignUpButtons } from '../SignUpMenu/SignUpButtons';
import NotificationIcon from 'components/ui/NotificationIcon';

const noLoggerAppBarStyle = {
  position: 'relative',
  height: '470px',
  '@media screen and (minWidth: 600px)': {
    minHeight: '420px',
  },
  backgroundImage: `url(${appbar})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundBlendMode: 'soft-light',
};

const loggerAppBarStyle = {
  position: 'relative',
  zIndex: 999,
  background: '#101010',
};

export const MainAppBar = () => {
  const user = useSelector(selectUser);
  const isUserLocationPermission = useSelector(selectUserLocation);
  const receivingNotification = useSelector(selectUserNotify);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const matches = useMediaQuery(json2mq({ minWidth: 811 }));

  const handleScrollToMap = () => {
    const mapElement = document.getElementById('map');

    mapElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const [isNavButtonsOpen, setNavButtonsOpen] = useState(false);
  const [, setArrowButtonClicked] = useState(false);

  const toggleNavButtons = () => {
    setNavButtonsOpen(!isNavButtonsOpen);
  };

  const handleArrowButtonClick = () => {
    toggleNavButtons();
    setArrowButtonClicked(true);
  };

  return (
    <AppBar style={user ? loggerAppBarStyle : noLoggerAppBarStyle}>
      <Container>
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0 !important',
          }}
        >
          <Box
            sx={{
              width: '100%',
              alignItems: user ? 'center' : 'flex-start',
              justifyContent: user ? 'space-between' : 'flex-end',
            }}
          >
            {user ? (
              <Box>
                <SideBar />
                <Link to="/">
                  <Box>
                    <img
                      alt="logo"
                      src={logo1}
                      style={{ height: '40px', width: '52px' }}
                    />
                    {''}
                    {matches && (
                      <img
                        alt="logo2"
                        src={logo2}
                        style={{
                          paddingLeft: '8px',
                          width: '160px',
                          height: '18px',
                        }}
                      />
                    )}
                  </Box>
                </Link>
              </Box>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  transform: 'translate(-50%, -45%)',
                  width: '100%',
                  maxWidth: '800px',
                  display: 'block',
                }}
              >
                <Box alignItems={'normal'}>
                  <Box sx={{ width: '35%' }}>
                    <Link to="/">
                      <img alt="logo" src={logo1} />
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      flexDirection: 'column',
                      width: '65%',
                      alignItems: 'flex-start',
                    }}
                  >
                    <img
                      alt="logo2"
                      src={logo2}
                      style={{
                        maxHeight: 60,
                        marginBottom: 24,
                      }}
                    />
                    <Box
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'self-start',
                      }}
                    >
                      <Typography
                        variant="text"
                        sx={{
                          textTransform: 'uppercase',
                          whiteSpace: `${matches ? 'nowrap' : 'wrap'}`,
                          fontFamily: 'Montserrat',
                          fontSize: `${matches ? '24px' : '16px'}`,
                        }}
                      >
                        {t('ui:appBar.desc1')}
                      </Typography>
                      <Typography
                        variant="text"
                        sx={{
                          textTransform: 'uppercase',
                          whiteSpace: `${matches ? 'nowrap' : 'wrap'}`,
                          fontFamily: 'Montserrat',
                          fontSize: `${matches ? '24px' : '16px'}`,
                        }}
                      >
                        {t('ui:appBar.desc2')}
                      </Typography>
                      <Button
                        variant="contained"
                        children={
                          isHomePage
                            ? t('ui:button.currentEvents')
                            : t('ui:button.home')
                        }
                        onClick={
                          isHomePage ? handleScrollToMap : () => navigate('/')
                        }
                        style={{ marginTop: 24, alignItems: 'center' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                justifyItems: 'center',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: 'auto',
                flexDirection: 'row',
              }}
            >
              {user && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 1,
                  }}
                >
                  {location.pathname !== '/' && (
                    <Link sx={{ color: '#B9B9B9' }} component={Link} to="/">
                      {matches ? (
                        <Button aria-label="flex button group">
                          {t('ui:topMenu.home')}
                        </Button>
                      ) : (
                        <Tooltip title={t('ui:button.home')}>
                          <IconButton>
                            <HomeOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Link>
                  )}

                  {matches && (
                    <>
                      <Link
                        sx={{ color: '#B9B9B9' }}
                        component={Link}
                        to="/fullMap"
                      >
                        <Tooltip title={t('ui:topMenu.fullMap')}>
                          <Button aria-label="flex button group">
                            {t('ui:button.map')}
                          </Button>
                        </Tooltip>
                      </Link>

                      <Link to={`/report`}>
                        <Tooltip title={t('ui:topMenu.createReport')}>
                          <Button aria-label="flex button group">
                            {t('ui:button.addNewReport')}
                          </Button>
                        </Tooltip>
                      </Link>
                    </>
                  )}
                </Box>
              )}
              <Box mr={1}>{!user && <SignUpButtons />}</Box>
              {user && receivingNotification && (
                <NotificationIcon
                  isUserLocationPermission={isUserLocationPermission}
                  userId={user}
                />
              )}
            </Box>

            <Box
              sx={{
                textTransform: 'capitalize',
                fontSize: '14px',
              }}
              className="SwichLangBox"
            >
              <SwitchLanguage />
            </Box>
          </Box>
          {user && (
            <Box>
              {!matches && (
                <Accordion expanded={isNavButtonsOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={handleArrowButtonClick}
                    sx={{
                      p: 0.5,
                      minHeight: '24px !important',
                      background: '#101010',
                      m: '0 !important',
                    }}
                  />
                  <AccordionDetails
                    sx={{ width: '100%', p: 0, background: '#101010' }}
                  >
                    {isNavButtonsOpen && (
                      <>
                        <Link
                          sx={{ color: '#B9B9B9' }}
                          component={Link}
                          to="/fullMap"
                        >
                          <Button
                            aria-label="flex button group"
                            sx={{
                              textTransform: 'capitalize',
                              mr: 1,
                            }}
                          >
                            {t('ui:button.map')}
                          </Button>
                        </Link>

                        <Link to={`/report`}>
                          <Button
                            aria-label="flex button group"
                            sx={{
                              textTransform: 'capitalize',

                              mr: 1,
                            }}
                          >
                            {t('ui:button.addNewReport')}
                          </Button>
                        </Link>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          )}
          {!user && isHomePage && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                bgcolor: '#000000',
                width: '100%',
                paddingY: 2,
                borderRadius: '4px 4px 0 0',
              }}
            >
              <img
                alt="logo2"
                src={logo2}
                style={{
                  paddingLeft: '32px',
                  width: '288px',
                  height: '32px',
                }}
              />{' '}
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
