import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, Divider } from '@mui/material';
import {
  selectUser,
  selectUserEmail,
  selectUserName,
  selectUserMembershipLevel,
} from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';
import { Container } from 'layouts/Container/Container';
import Map from 'components/common/Map/Map';
import { endpoints } from 'utils/constants';
import { fetchReports } from 'utils/reportLoader';
import {
  setToLocalStorage,
  getFromLocalStorage,
} from 'utils/localStorageHelper';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { SocialMedia } from 'components/common/SocialMedia/SocialMedia';
import { MainReportsBlock } from 'components/common/MainReportsBlock/MainReportsBlock';
import './MainPage.style.css';
import { useParams } from 'react-router-dom';
import { countryNameToIsoMap } from 'utils/constants';

const HomePage = ({ mapCenter }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const nameUser = useSelector(selectUserName);
  const emailUser = useSelector(selectUserEmail);
  const superUser = useSelector(selectUserMembershipLevel);
  const today = new Date().toLocaleDateString();
  const isScreenLessThan1024px = useMediaQuery('(max-width:1023px)');
  const [endpointData, setEndpointData] = useState({});
  const [endpointChecks, setEndpointChecks] = useState({});
  const { trendingCountry } = useParams();

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: -1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.title = 'Sleuth Net';
    smoothScrollToTop();
  }, []);

  const updateEndpointChecks = data => {
    const checks = Object.keys(data).reduce((acc, reportType) => {
      acc[reportType] = true;
      return acc;
    }, {});
    setEndpointChecks(checks);
  };

  useEffect(() => {
    const loadReports = async () => {
      const cachedData = getFromLocalStorage('reports');
      if (cachedData) {
        setEndpointData(cachedData);
        updateEndpointChecks(cachedData);
      } else {
        try {
          const reports = await fetchReports();
          setEndpointData(reports);
          updateEndpointChecks(reports);
          setToLocalStorage('reports', reports, 3600000);
        } catch (error) {
          console.error('Error loading reports:', error);
        }
      }
    };

    loadReports();
  }, []);

  return (
    <Container>
      <Box className="MainBox">
        {user && (
          <>
            <Box className="UserMainBox">
              <Typography>
                {t('ui:mainPage.welcomeMessage')},{' '}
                {nameUser ? (
                  <strong>{nameUser}</strong>
                ) : (
                  <strong>{emailUser}</strong>
                )}
              </Typography>
              <Typography textAlign={'end'}>
                {t('ui:mainPage.date')}: <strong>{today}</strong>
              </Typography>
            </Box>

            <Box
              className="UserMainContectsBox"
              sx={{
                alignItems: isScreenLessThan1024px ? 'center' : 'start',
                flexDirection: isScreenLessThan1024px ? 'column' : 'row',
              }}
            >
              <Box
                flexDirection={'column'}
                sx={{ width: isScreenLessThan1024px ? '100%' : '70%' }}
              >
                {superUser === 'superUser' && (
                  <MainReportsBlock
                    endpointData={endpointData}
                    userMemberShipLevel={superUser}
                  />
                )}
                {/* <AdSenseBlock
                  style={{
                    width: '100%',
                    minHeight: '8rem',
                    color: 'black',
                    marginBottom: '1rem',
                  }}
                /> */}

                <Map
                  setEndpointChecks={setEndpointChecks}
                  endpointChecks={endpointChecks}
                  endpointData={endpointData}
                  endpoints={endpoints}
                  mapCenter={mapCenter}
                  trendingCountry={trendingCountry}
                />
              </Box>

              {!isScreenLessThan1024px ? (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ marginX: '16px !important' }}
                />
              ) : (
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ marginY: '16px !important' }}
                />
              )}
              <Box
                sx={{
                  width: isScreenLessThan1024px ? '100%' : 400,
                  display: 'inline',
                }}
              >
                <Box
                  className="SocialMediaBox"
                  sx={{ height: '776px', width: '100%' }}
                >
                  <Typography variant="h2">
                    {t('ui:mainPage.newsTitle')}
                    {trendingCountry && (
                      <strong>
                        {' '}
                        {t(
                          `countries:countries.${countryNameToIsoMap[trendingCountry]}.name`
                        )}
                      </strong>
                    )}
                  </Typography>
                  <SocialMedia trendingCountry={trendingCountry} />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};
export default HomePage;
