// UserLocationButton.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip,IconButton, Box } from '@mui/material';
import { MapControl } from '@vis.gl/react-google-maps';
import MyLocationIcon from '@mui/icons-material/MyLocation';

const UserLocationButton = ({ onCenterUserLocation, controlPosition }) => {
 
  const { t } = useTranslation();

  return (
    <MapControl position={controlPosition}>
      <Box
        sx={{
          position: 'absolute',
          margin: '10px',
          right: 0,
          top: 60,
          display: 'inline-flex',
        }}
      >
        <Tooltip title={t('ui:map.myLocation')}>
            <IconButton
              onClick={onCenterUserLocation}
              color="primary"
              sx={{
                padding: 1,
                bgcolor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px -1px',
              }}
            >
              <MyLocationIcon />
            </IconButton>
          </Tooltip>
       
      </Box>
    </MapControl>
  );
};

export default UserLocationButton;
