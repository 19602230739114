import { Modal, Box, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './AlertModal.styled.css';
import 'utils/i18next';
import { MainButton, SecondMainButton } from 'components/ui/Button/Button';
import CloseIcon from '@mui/icons-material/Close';

export function AlertModal({
  open,
  handleClose,
  text,
  handleConfirm,
  children,
  buttonText,
  onClose,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="AlertWrapper">
          {children && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                transition: 'background-color 0.3s',
                ':hover': {
                  backgroundColor: '#2196f3',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography align={'center'}>{text}</Typography>
          {children}
          <div className="ButtonWrapper">
            {!children && (
              <MainButton
                children={buttonText ? buttonText : t('ui:button.yes')}
                onClick={handleConfirm}
              />
            )}
            {handleClose ? (
              <SecondMainButton
                children={t('ui:button.no')}
                onClick={handleClose}
              />
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
