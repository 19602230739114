import React, { createContext, useContext, useState } from 'react';
import CustomSnackbar from './CustomSnackbar';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info', // 'success' | 'error' | 'warning' | 'info'
  });

  const showSnackbar = (message, severity = 'info', duration = 3000) => {
    setSnackbar({ open: true, message, severity, autoHideDuration: duration });
  };

  const hideSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={hideSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
