import { createTheme } from '@mui/material/styles';

export const MainTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196F3',
      contrastText: '#cdcdcd',
    },
    secondary: {
      main: '#792F2A',
    },
    success: {
      main: '#2e7d32',
    },
    text: {
      primary: '#dedede',
      disabled: 'rgba(255,255,255,0.13)',
    },
    background: {
      paper: '#1d1d1d',
    },
  },
  typography: {
    subtitle1: {
      fontSize: '10pt',
    },
    subtitle2: {
      fontSize: '12pt',
    },
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '18pt',
      fontWeight: 600,
    },
    h2: {
      fontSize: '15pt',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '14pt',
    },
    h4: {
      fontSize: '13pt',
    },
    button: {
      fontSize: '12pt',
    },
    overline: {
      fontSize: '11pt',
    },
    body1: {
      fontSize: '12pt',
    },
    body2: {
      fontSize: '11pt',
    },
  },
  shape: {
    borderRadius: 2,
  },
  backgrounds: {
    default: '#ebf0f5',
    light: '#D9D9D9',
    paper: '#a7c8d6',
    bodyPrimary: '#B9B9B9',
    primeGrey: '#333537',
    bodySecondary: '#F0F1F3',
    buttonPrimary: '#2196F3',
    buttonSecondary: '#B9B9B9',
    loaderPrimary: '#FC842D',
    backdrop: 'rgba(0, 0, 0, 0.4)',
    userInfo: '#EFF1F3',
    sidebar: '#F0F1F3',
    userBack: 'transparent',
    modalOverlay: 'rgba(33, 33, 33, 0.12)',
    dark: '#101010',
    mapBorder: '#133a56',
  },
  fontColors: {
    primary: '#D2D2D2',
    secondary: '#212121',
    modify: '#FC842D',
    buttonPrimary: '#FFFFFF',
    buttonSecondary: '#03afff',
    link: '#13191C',
    linkSecondary: '#03afff',
  },
  transitions: {
    primary: '0.5s ease 0s',
  },
});
