import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  ListItemButton,
  Typography,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { UserLogOut } from 'components/common/UserMenu/UserLogOut';
import { DASHBOARD_URL } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from 'assets/img/menu-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import './SideBar.styled.css';

export const SideBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleMenu = event => {
    event.stopPropagation();
    setIsOpen(prev => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChildItemClick = () => {
    closeMenu();
  };

  return (
    <Box>
      <IconButton onClick={toggleMenu} color="inherit" size="large">
        {isOpen ? (
          <CloseIcon fontSize="30px" />
        ) : (
          <Tooltip title={t('ui:topMenu.openMenu')}>
            <MenuIcon />
          </Tooltip>
        )}
      </IconButton>

      {isOpen && (
        <Box ref={sidebarRef} className="SideBarWrapper">
          <List>
            {/* Trending Section */}
            <ListItem>
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={t('ui:mainMenu.trendingTitle')}
                  />
                </ListItem>

                <List sx={{ px: '2rem', textTransform: 'uppercase' }}>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/trending/Ukraine"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('countries:countries.UA.name')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/trending/Israel"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('countries:countries.IL.name')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/coming-soon"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.hurricane')}
                    </Typography>
                  </ListItemButton>
                </List>
              </List>
            </ListItem>

            {/* Report Section */}
            <ListItem>
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/report"
              >
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={16}>
                  {t('ui:mainMenu.reportsTitle')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* Forum Section */}
            <ListItem>
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/coming-soon"
              >
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={16}>
                  {t('ui:mainMenu.forumTitle')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* OSINT Section */}
            <ListItem>
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                // component={Link}
                // to="/dashboard"
                component="a"
                href={`${DASHBOARD_URL}/admin/dashboard`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={16}>
                  {t('ui:mainMenu.dashboardTitle')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* Account Section */}
            <ListItem>
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={t('ui:mainMenu.accountTitle')}
                  />
                </ListItem>

                <List sx={{ px: '2rem', textTransform: 'uppercase' }}>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/account"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.editAccount')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/account/my-reports"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.viewReports')}
                    </Typography>
                  </ListItemButton>
                </List>
              </List>
            </ListItem>

            <Divider />

            {/* Logout */}
            <ListItem>
              <ListItemButton sx={{ padding: 0, mt: 2 }}>
                <UserLogOut
                  label={t('ui:mainMenu.logout')}
                  styled={{
                    width: '100%',
                    textTransform: 'uppercase',
                    justifyContent: 'normal',
                    padding: '8px 16px',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};
