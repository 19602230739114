// MapComponent.jsx

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUser,
  selectUserLocation,
  selectUserMembershipLevel,
} from 'redux/auth/authSlice';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import {
  APIProvider,
  Map,
  InfoWindow,
  useMap,
  ControlPosition,
  Marker,
} from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Tooltip,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import {
  getUserLocation,
  fetchUserLocationByIP,
} from 'services/getUserLocation';
import PhotoViewer from 'components/ui/Modal/PhotoViewer';
import VideoViewer from 'components/ui/Modal/VideoViewer';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import './Map.style.css';
import { languageSelectors } from 'redux/language';
import MapHandler from './SearchBox/MapHandler';
import { SearchBox } from './SearchBox/SearchBox';
import myLocation from 'assets/img/myLocation.png';
import UserLocationButton from './UserLocationButton';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const MapComponent = ({
  endpoints,
  endpointData,
  endpointChecks,
  // showRecentReports,
  mapCenter,
  trendingCountry,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const superUser = useSelector(selectUserMembershipLevel);
  const userGeoLocation = useSelector(selectUserLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(languageSelectors.getLanguage);
  const [userLocation, setUserLocation] = useState(null);
  const [isUserCentered, setIsUserCentered] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMedia, setOpenMedia] = useState(false);
  const [photoViewerData, setPhotoViewerData] = useState({
    photos: [],
    isOpen: false,
  });
  const [videoViewerData, setVideoViewerData] = useState({
    videos: [],
    isOpen: false,
  });
  const [countryCenter, setCountryCenter] = useState(mapCenter);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const mapRef = useRef(null);

  const dateSeconds =
    selectedMarker?.data?.timeObserved?.seconds ||
    selectedMarker?.data?.dateTime?.seconds ||
    selectedMarker?.data?.timeReported?.seconds;

  const date = dateSeconds ? new Date(dateSeconds * 1000) : new Date();

  const formatDate = (date, lang) => {
    let options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    if (lang === 'ua') {
      options.locale = 'uk-UA';
    } else {
      options.locale = 'en-US';
    }

    return date.toLocaleDateString(options.locale, options);
  };

  const formattedDate = formatDate(date, lang);

  const formattedTime =
    date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: lang === 'ua' ? false : true,
    }) || 'Unknown Time';

  const formattedDateTime = dateSeconds
    ? `${formattedDate} ${formattedTime}`
    : '';

  const updateCenter = newLocation => {
    setUserLocation(newLocation);
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
    }
  };

  useEffect(() => {
    async function fetchAndUpdateUserLocation() {
      try {
        let storedLocation = localStorage.getItem('userLocation');
        if (storedLocation) {
          const parsedLocation = JSON.parse(storedLocation);
          setUserLocation(parsedLocation);
          updateCenter(parsedLocation);
        } else {
          const locationByIP = await fetchUserLocationByIP();
          if (locationByIP) {
            setUserLocation(locationByIP);
            localStorage.setItem('userLocation', JSON.stringify(locationByIP));
            updateCenter(locationByIP);
          } else {
            console.error('Could not get user location by IP');
          }
        }

        const geolocation = userGeoLocation && (await getUserLocation());
        if (geolocation) {
          setUserLocation(geolocation);
          localStorage.setItem('userLocation', JSON.stringify(geolocation));
          updateCenter(geolocation);
        }
      } catch (error) {
        console.error(
          'Error fetching or updating user location:',
          error.message || error
        );
      }
    }

    fetchAndUpdateUserLocation();
  }, [userGeoLocation]);

  useEffect(() => {
    async function initializeMap() {
      const location = await getUserLocation(updateCenter);
      if (location) {
        setUserLocation(location);
      }
    }

    initializeMap();
  }, []);

  useEffect(() => {
    if (userLocation) {
      localStorage.setItem('userLocation', JSON.stringify(userLocation));
    }
  }, [userLocation]);

  // const filteredReports = useMemo(() => {
  //   return endpoints.reduce((filteredData, { id }) => {
  //     if (endpointChecks[id]) {
  //       let reports = endpointData[id] || [];

  //       // Filter by trendingCountry if it's set
  //       if (trendingCountry) {
  //         reports = reports.filter(
  //           report => report.country === trendingCountry
  //         );
  //       }

  //       filteredData[id] = reports;
  //     }
  //     return filteredData;
  //   }, {});
  // }, [endpointChecks, endpointData, endpoints, trendingCountry]);

  const filteredReports = useMemo(() => {
    const timeDelay = 48 * 60 * 60 * 1000; // 48 hours in ms
    const currentTime = Date.now();

    return endpoints.reduce((filteredData, { id }) => {
      if (endpointChecks[id]) {
        let reports = endpointData[id] || [];

        // Filter by trendingCountry if it's set
        if (trendingCountry) {
          reports = reports.filter(
            report => report.country === trendingCountry
          );
        }

        // Apply logic for militaryActivity reports based on user level
        reports = reports.filter(report => {
          if (report.reportType === 'militaryActivity') {
            // Check if the report is older than 48 hours or if the user is a superUser
            const reportTime =
              (report.timeObserved?.seconds || 0) * 1000 +
              (report.timeObserved?.nanoseconds || 0) / 1e6;

            // Only show items older than 48 hours, unless the user is a superUser
            if (currentTime - reportTime > timeDelay || superUser) {
              return true;
            }
            return false;
          }
          return true; // Keep all other report types
        });

        filteredData[id] = reports;
      }
      return filteredData;
    }, {});
  }, [endpointChecks, endpointData, endpoints, trendingCountry, superUser]);

  const handleCenterUserLocation = () => {
    if (userLocation) {
      setIsUserCentered(true);
    }
  };

  useEffect(() => {
    if (mapCenter) {
      setCountryCenter(mapCenter);

      if (mapRef.current) {
        mapRef.current.setCenter(mapCenter);
      }
    }
  }, [mapCenter]);

  useEffect(() => {
    if (selectedPlace && selectedPlace.geometry) {
      const location = selectedPlace.geometry.location;
      setCountryCenter(location); // Update the center to the selected place
    }
  }, [selectedPlace]);

  const handleMarkerClick = (event, markerPosition, data) => {
    setSelectedMarker({ position: markerPosition, data });
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenMedia(prev => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMedia(false);
  };

  const closePhotoViewer = () => {
    setPhotoViewerData({ photos: [], isOpen: false });
  };

  const closeVideoViewer = () => {
    setVideoViewerData({ videos: [], isOpen: false });
  };

  const handleViewReportClick = report => {
    dispatch(setSelectedReport(report));
    navigate(`/report/view/${report.reportId || report.eventId}`);
  };

  const getIconUrl = (id, type) => {
    // const safeType = type ?? 'unknown';
    let iconUrl;
    try {
      // Try to load the icon based on the 'type' field
      iconUrl = require(`assets/img/typeofendpoints/${type}.png`);
    } catch (error) {
      iconUrl = require(`assets/img/typeofendpoints/${id}.png`);
    }
    return iconUrl;
  };

  if (!API_KEY) {
    return (
      <Box className="containerStyle">
        <CircularProgress color="primary" size={60} />
        <p>Error: Missing API Key</p>
      </Box>
    );
  }

  return (
    <Box className="mapMapBox">
      {userLocation ? (
        <Box className="mapContainer">
          <APIProvider apiKey={API_KEY}>
            {/* Button to center map on user's location */}
            <UserLocationButton
              onCenterUserLocation={handleCenterUserLocation}
              controlPosition={ControlPosition.RIGHT_TOP}
            />
            <SearchBox
              controlPosition={ControlPosition.RIGHT_TOP}
              onPlaceSelect={setSelectedPlace}
            />
            <Map
              ref={mapRef}
              className="containerStyle"
              defaultCenter={countryCenter || userLocation}
              defaultZoom={6}
              gestureHandling={'greedy'}
              options={{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                draggable: true,
              }}
            >
              {' '}
              {userLocation && (
                <Marker
                  position={userLocation}
                  icon={myLocation}
                  title="I'm here"
                />
              )}
              <Markers
                points={filteredReports}
                onMarkerClick={handleMarkerClick}
                getIconUrl={getIconUrl || undefined}
              />
              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker.position}
                  onCloseClick={handleInfoWindowClose}
                  options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  className="gm-style"
                >
                  <Box
                    sx={{
                      color: 'black',
                      display: 'block',
                      overflow: 'hidden',
                      textAlign: 'left',
                      fontSize: '16px',
                    }}
                  >
                    <div>
                      <Typography
                        variant="h3"
                        color={'black'}
                        fontWeight={500}
                        children={t(
                          `hazards:hazard.${selectedMarker.data.reportType}.name`
                        )}
                      />
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        mb={1}
                        children={
                          selectedMarker.data.hazardType
                            ? t(
                                `hazards:hazard.${selectedMarker.data.reportType}.items.${selectedMarker.data.hazardType}`
                              )
                            : selectedMarker.data.bombType
                        }
                      />
                    </div>

                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        textTransform={'uppercase'}
                        fontWeight={400}
                        mb={1}
                        children={`${t('ui:map.verificationStatus')}:  ${
                          selectedMarker.data.verificationStatus
                            ? t(
                                `ui:map.${selectedMarker.data.verificationStatus}`
                              )
                            : t('ui:map.inProgress')
                        }`}
                      />
                    </div>

                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        textTransform={'uppercase'}
                        fontWeight={400}
                        mb={1}
                        children={`${t('ui:map.safetyStatus')}:  ${
                          selectedMarker.data.verifiedSafetyStatus
                            ? t(
                                `ui:map.${selectedMarker.data.verifiedSafetyStatus}`
                              )
                            : t('ui:map.unknown')
                        }`}
                      />
                    </div>

                    <div>
                      <Typography
                        variant="overline"
                        sx={{
                          color: 'black',
                          mb: 1,
                          textTransform: 'unset',
                        }}
                        children={formattedDateTime}
                      />
                    </div>

                    <Box justifyContent={'space-between'}>
                      <Tooltip title={t('ui:button.openReport')}>
                        <Button
                          variant="contained"
                          // fullWidth
                          sx={{
                            mr: 1,
                            fontSize: '12px',
                            fontWeight: 600,
                            borderRadius: 2,
                            width: '132px',
                          }}
                          onClick={() =>
                            handleViewReportClick(selectedMarker.data)
                          }
                        >
                          {t('ui:button.openReport')}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          user ? t('ui:map.userMedia') : t('ui:map.noUserMedia')
                        }
                      >
                        <Button
                          variant="outlined"
                          // fullWidth
                          sx={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: '#2196F3 !important',
                            borderRadius: 2,
                            width: '132px',
                          }}
                          onClick={handleClick}
                        >
                          {t('ui:button.viewMedia')}
                        </Button>
                      </Tooltip>
                    </Box>
                    {user && (
                      <Popper
                        open={openMedia}
                        anchorEl={anchorEl}
                        placement="top-start"
                        style={{ zIndex: 1300 }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box
                            sx={{
                              borderRadius: 4,
                              p: 2,
                              bgcolor: '#1d1d1d',
                              fontWeight: 'bold',
                            }}
                          >
                            {selectedMarker?.data?.videoUrl?.length > 0 ||
                            selectedMarker?.data?.photoUrl?.length > 0 ? (
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                {selectedMarker?.data?.videoUrl?.length > 0 &&
                                  selectedMarker.data.videoUrl !== 'empty' && (
                                    <div>
                                      <p
                                        style={{
                                          marginRight: 16,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setVideoViewerData({
                                            videos:
                                              selectedMarker.data.videoUrl,
                                            isOpen: true,
                                          })
                                        }
                                      >
                                        {t('ui:map.video')}
                                      </p>
                                    </div>
                                  )}
                                <VideoViewer
                                  open={videoViewerData.isOpen}
                                  onClose={closeVideoViewer}
                                  videos={videoViewerData.videos}
                                />
                                {selectedMarker?.data?.photoUrl?.length > 0 &&
                                  selectedMarker.data.photoUrl !== 'empty' && (
                                    <div>
                                      <p
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setPhotoViewerData({
                                            photos:
                                              selectedMarker.data.photoUrl.filter(
                                                url =>
                                                  !url.includes('_screenshot')
                                              ),
                                            isOpen: true,
                                          })
                                        }
                                      >
                                        {t('ui:map.photo')}
                                      </p>
                                    </div>
                                  )}
                                <PhotoViewer
                                  photos={photoViewerData.photos}
                                  open={photoViewerData.isOpen}
                                  onClose={closePhotoViewer}
                                />
                              </Box>
                            ) : (
                              <Typography>{t('ui:map.noMedia')}</Typography>
                            )}
                          </Box>
                        </ClickAwayListener>
                      </Popper>
                    )}
                  </Box>
                </InfoWindow>
              )}
            </Map>
            <MapHandler
              ref={mapRef}
              place={selectedPlace}
              mapCenter={mapCenter}
              userLocation={userLocation}
              isUserCentered={isUserCentered}
              setIsUserCentered={setIsUserCentered}
            />
          </APIProvider>
        </Box>
      ) : (
        <Box className="containerStyle">
          <CircularProgress color="primary" size={60} />
          <Typography>{t('ui:map.loadingLocation')}</Typography>
        </Box>
      )}
    </Box>
  );
};

const Markers = ({ points, onMarkerClick, getIconUrl }) => {
  const { t } = useTranslation();
  const map = useMap();
  const [markers, setMarkers] = useState([]);
  const clusterer = useRef(null);

  useEffect(() => {
    if (!map) return;

    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(markers);
  }, [markers]);

  useEffect(() => {
    if (!map) return;

    const newMarkers = [];

    Object.entries(points).forEach(([id, reports]) => {
      reports.forEach(report => {
        const marker = new window.google.maps.Marker({
          position: {
            lat: Number(report.latitude),
            lng: Number(report.longitude),
          },
          title: `${new Date(
            report.timeObserved.seconds * 1000
          ).toLocaleString()} ⚠️ ${
            report.bombType ||
            (report.hazardType
              ? t(
                  `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                )
              : t('ui:map.undefined'))
          }`,
          icon: {
            url: getIconUrl(id, report.hazardType),
            scaledSize: new window.google.maps.Size(35, 35),
          },
        });

        marker.addListener('click', event => {
          onMarkerClick(
            event,
            {
              lat: Number(report.latitude),
              lng: Number(report.longitude),
            },
            {
              id,
              ...report,
            }
          );
          map.setZoom(15);
          map.setCenter(marker.getPosition());
        });

        newMarkers.push(marker);
      });
    });

    setMarkers(newMarkers);
  }, [points, map, getIconUrl, onMarkerClick, t]);

  return null;
};

export default MapComponent;
